<template>
  <NuxtImg
    v-if="session && session.user && session.user.avatar"
    :src="session.user.avatar"
    class="rounded-full w-10 h-10"
    format="webp"
    preload
    quality="100"
    sizes="sm:40px md:40px lg:120px"
  />
</template>

<script lang="ts" setup>
import {getCSpotSession} from '~/composables/auth/useAuth'

const session = getCSpotSession()
</script>
